import React, {
  InputHTMLAttributes,
  useState,
  FocusEvent,
  forwardRef,
} from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as Styled from './styles';
import { Margin } from '@app/utils/modifiers';

/**
 * Input
 */
export interface InputProps
  extends InputHTMLAttributes<HTMLInputElement>,
    Styled.StyledInputProps {
  id?: string;
  /**
   * show icon on the right side
   */
  name?: string;
  margin?: Margin;
  width?: string;
  required?: boolean;
  removeBorder?: boolean;
  staticTitle?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      name,
      margin,
      width,
      required,
      removeBorder,
      staticTitle,
      mask,
      value,
      placeholder,
      disabled,
      variant,
      $valid = true,
      type,
      readOnly,
      ...rest
    }: InputProps,
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleBlur = (event: FocusEvent<HTMLInputElement, Element>) => {
      setIsFocused(false);
    };

    const clickFloatLabel = () => {
      if (id) {
        const input = document.getElementById(id);
        input?.focus();
      }
    };

    const returnPlaceholder = () => {
      if (staticTitle) return placeholder;
      return '';
    };

    return (
      <Styled.TextFieldWrapper width={width}>
        {!staticTitle && (
          <Styled.FloatLabel
            up={Boolean((value as string).length || isFocused)}
            onClick={clickFloatLabel}
            disabled={disabled}
            variant={variant}
          >
            {placeholder}
          </Styled.FloatLabel>
        )}
        <Styled.InputContainer
          margin={margin}
          disabled={disabled}
          variant={variant}
          $valid={$valid}
          removeBorder={removeBorder}
          readOnly={readOnly}
        >
          {mask ? (
            <Styled.MaskedInput
              id={id}
              name={name}
              mask={mask}
              disabled={disabled}
              onFocus={() => setIsFocused(true)}
              onBlur={handleBlur}
              placeholder={returnPlaceholder()}
              // @ts-ignore: ref property throwing ts error in this library but works correctly
              ref={ref}
              readOnly={readOnly}
              {...rest}
            />
          ) : (
            <Styled.Input
              id={id}
              name={name}
              type={type}
              disabled={disabled}
              onFocus={(e) => {
                setIsFocused(true);
              }}
              onBlur={handleBlur}
              placeholder={returnPlaceholder()}
              ref={ref}
              readOnly={readOnly}
              {...rest}
            />
          )}
          {required && (
            <Styled.RequiredButton disabled={disabled}>
              <FontAwesomeIcon icon={['far', 'asterisk']} />
            </Styled.RequiredButton>
          )}
        </Styled.InputContainer>
      </Styled.TextFieldWrapper>
    );
  },
);

export default Input;
