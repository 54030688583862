import IconButton from '@app/components/atoms/IconButton';
import Link from 'next/link';
import React, { HTMLAttributes } from 'react';

import * as Styled from './styles';

/**
 * Submission Message
 */
export interface SubmissionMessageProps extends HTMLAttributes<HTMLDivElement> {
  clickClose?: () => void;
  title: string;
  subtitle: string;
  link?: string;
  bgColor?: string;
  titleMargin?: number;
}

const SubmissionMessage = ({
  clickClose,
  title,
  subtitle,
  link,
  bgColor,
  titleMargin,
  ...rest
}: SubmissionMessageProps) => {
  const copyToClipboard = () => {
    if (link) {
      navigator.clipboard.writeText(link);
    }
  };

  return (
    <Styled.Container {...rest} bgColor={bgColor}>
      <Styled.CloseContainer>
        <IconButton iconType="close" onClick={clickClose && clickClose} />
      </Styled.CloseContainer>

      <Styled.Title
        variant="xl"
        bold
        color="#4F4F4F"
        marginBottom={titleMargin}
      >
        {title}
      </Styled.Title>
      <Styled.Subtitle variant="base" color="#767676">
        {subtitle}
      </Styled.Subtitle>

      {link ? (
        <Styled.CopyContainer row itemsCenter justifyBetween>
          <Link href={link} passHref>
            <a target="_blank">
              <Styled.Link variant="sm" bold color="#D47E5B">
                {link}
              </Styled.Link>
            </a>
          </Link>

          <IconButton
            iconType="copy"
            label="COPY"
            actionMessage="Copied!"
            iconSize="sm"
            onClick={copyToClipboard}
          />
        </Styled.CopyContainer>
      ) : null}
    </Styled.Container>
  );
};

export default SubmissionMessage;
