import styled, { css } from 'styled-components';

export const Container = styled.div<{ bgColor?: string }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ bgColor }) => bgColor || 'transparent'};
`;

export const BigContainer = styled.div<{
  hasBorderTop?: boolean;
  absoluteHeader?: boolean;
}>`
  width: 100%;
  box-sizing: border-box;
  border-top: ${({ hasBorderTop }) =>
    hasBorderTop ? '2px solid #D7D8D8' : 'none'};

  ${({ absoluteHeader }) =>
    absoluteHeader &&
    css`
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      width: auto;
      padding: 0;
    `}

  @media screen and (max-width: 1440px) {
    ${({ absoluteHeader }) =>
      absoluteHeader &&
      css`
        position: absolute;
        width: auto;
      `}
  }
`;

export const FooterContainer = styled.div<{ hasBorderTop?: boolean }>`
  width: 100%;
  border-top: ${({ hasBorderTop }) =>
    hasBorderTop ? '2px solid #d6d5d5' : 'none'};
`;

export const ChildrenContainer = styled.div<{ isModal?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 500px;
  min-height: ${({ isModal }) => (isModal ? 'auto' : '70vh')};

  @media screen and (max-width: 975px) {
    min-height: 300px;
    min-height: ${({ isModal }) => (isModal ? 'auto' : '70vh')};
  }
`;
