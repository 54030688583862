import { IconProp } from '@fortawesome/fontawesome-svg-core';
import * as Styled from './styles';
import { ToggleVariant } from './styles';

/**
 * Slide Toggle
 */
export interface SlideToggleProps {
  checked: boolean;
  handleToggle?: () => void;
  disabled?: boolean;
  variant?: ToggleVariant;
  yellow?: boolean;
  checkedIcon?: IconProp;
  uncheckedIcon?: IconProp;
  uncheckedBackgroundColor?: string;
  uncheckedBorderColor?: string;
}

const SlideToggle = ({
  checked,
  handleToggle,
  disabled,
  variant = 'primary',
  yellow,
  checkedIcon,
  uncheckedIcon,
  uncheckedBackgroundColor,
  uncheckedBorderColor,
  ...rest
}: SlideToggleProps) => (
  <Styled.Container>
    <Styled.SwitchLabel
      htmlFor="react-switch-new"
      variant={variant}
      checked={checked}
      disabled={disabled}
      yellow={yellow}
      uncheckedBackgroundColor={uncheckedBackgroundColor}
      uncheckedBorderColor={uncheckedBorderColor}
      onClick={
        !disabled
          ? (e: any) => {
              e.stopPropagation();
              handleToggle && handleToggle();
            }
          : undefined
      }
      {...rest}
    >
      <Styled.SwitchButton checked={checked} disabled={disabled} />

      <Styled.Icon
        variant={variant}
        checked={checked}
        icon={checkedIcon || ['fas', 'check']}
      />
      <Styled.Icon
        variant={variant}
        checked={!checked}
        icon={uncheckedIcon || ['fas', 'times']}
      />
    </Styled.SwitchLabel>
  </Styled.Container>
);

export default SlideToggle;
