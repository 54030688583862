import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';
import FlexBox from '../FlexBox';
import Text from '../Text';

export type StyledProps = {
  iconType:
    | 'copy'
    | 'copy-outline'
    | 'close'
    | 'pencil'
    | 'camera'
    | 'trash'
    | 'envelope'
    | 'phone';
} & IconProps;

type IconProps = {
  iconSize?: 'xl' | 'lg' | 'md' | 'sm';
  iconColor?: 'dark' | 'medium' | 'light' | 'orange';
};

export const Container = styled(FlexBox)`
  width: fit-content;
  height: fit-content;

  position: relative;
`;

export const Button = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;

  border: none;
  background: transparent;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
`;

export const Label = styled(Text)`
  margin-top: 4px;
  font-weight: 500;
`;

const sizeVariantModifiers = {
  xl: css`
    font-size: 24px;
  `,
  lg: css`
    font-size: 20px;
  `,
  md: css`
    font-size: 16px;
  `,
  sm: css`
    font-size: 14px;
  `,
};

const colorVariantModifiers = {
  dark: css`
    color: #4f4f4f;
  `,
  medium: css`
    color: #767676;
  `,
  light: css`
    color: #a4a4a4;
  `,
  orange: css`
    color: #d47e5b;
  `,
};

export const Icon = styled(FontAwesomeIcon)<IconProps>`
  ${({ iconSize = 'lg' }) => sizeVariantModifiers[iconSize]};
  ${({ iconColor = 'dark' }) => colorVariantModifiers[iconColor]};
`;

export const ActionMsg = styled(Text)`
  margin-top: 4px;

  position: absolute;
  top: 16px;
`;
