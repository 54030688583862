import styled from 'styled-components';
import FlexBox from '../../atoms/FlexBox';
import Text from '../../atoms/Text';

export const Container = styled(FlexBox)<{ bgColor?: string }>`
  width: 100%;
  height: 100%;
  padding: 51px 40px 93px;
  position: relative;
  box-sizing: border-box;
  background: ${({ bgColor }) => bgColor || 'transparent'};
`;

export const CloseContainer = styled.div`
  position: absolute;
  top: 21px;
  right: 25px;
`;

export const Title = styled(Text)<{ marginBottom?: number }>`
  margin-bottom: ${({ marginBottom }) => marginBottom || 2}px;
`;

export const Subtitle = styled(Text)``;

export const CopyContainer = styled(FlexBox)`
  width: 100%;
  margin-top: 40px;

  a {
    text-decoration: none;
  }
`;

export const Link = styled(Text)``;
