import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React, {
  ButtonHTMLAttributes,
  useEffect,
  useState,
  MouseEvent,
} from 'react';

import * as Styled from './styles';

/**
 * Icon Button
 */
export interface IconButtonProps
  extends Styled.StyledProps,
    ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  actionMessage?: string;
}

const IconButton = ({
  iconType,
  label,
  iconSize,
  iconColor,
  actionMessage,
  onClick,
  ...rest
}: IconButtonProps) => {
  const [actionVisible, setActionVisible] = useState(false);

  useEffect(() => {
    if (actionVisible && actionMessage && actionMessage.length > 0) {
      setTimeout(() => {
        setActionVisible(false);
      }, 10000);
    }
  }, [actionVisible, actionMessage]);

  const returnLabelSize = () => {
    switch (iconSize) {
      case 'xl':
      case 'lg':
        return 'sm';
      case 'md':
      case 'sm':
        return 'xs';
      default:
        return 'sm';
    }
  };

  const returnIcon = (): IconProp => {
    switch (iconType) {
      case 'copy':
        return ['fas', 'copy'];
      case 'copy-outline':
        return ['far', 'copy'];
      case 'close':
        return ['far', 'times'];
      case 'pencil':
        return ['fas', 'pencil'];
      case 'camera':
        return ['fas', 'camera'];
      case 'trash':
        return ['fas', 'trash'];
      case 'envelope':
        return ['fal', 'envelope'];
      case 'phone':
        return ['fal', 'phone'];
      default:
        return ['fas', 'copy'];
    }
  };

  const handleClickButton = (e: MouseEvent<HTMLButtonElement>) => {
    setActionVisible(true);
    onClick && onClick(e);
  };

  return (
    <Styled.Container itemsCenter>
      <Styled.Button {...rest} onClick={handleClickButton}>
        <Styled.Icon
          iconSize={iconSize}
          iconColor={iconColor}
          icon={returnIcon()}
        />
        {label && label.length > 0 ? (
          <Styled.Label variant={returnLabelSize()} color="#767676">
            {label}
          </Styled.Label>
        ) : null}
      </Styled.Button>

      <Styled.ActionMsg
        variant="xs"
        bold
        color="#D47E5B"
        style={{
          visibility:
            actionMessage && actionMessage.length > 0 && actionVisible
              ? 'visible'
              : 'hidden',
        }}
      >
        {actionMessage}
      </Styled.ActionMsg>
    </Styled.Container>
  );
};

export default IconButton;
