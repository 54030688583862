export const CREATE_WAITLIST = `
  mutation createWaitlist(
    $firstName: String,
    $lastName: String,
    $email: String!
    $phone: String,
    $isAgent: Boolean,
    $agentLicense: String,
    $agentMarkets: String,
  ) {
    createWaitlist(
      body: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        isAgent: $isAgent
        agentLicense: $agentLicense
        agentMarkets: $agentMarkets
      }
    ) {
      waitlistId
    }
  }
`;
