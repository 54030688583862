import styled from 'styled-components';

import { FlexBox, Text } from '@app/components/atoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Overlay = styled(FlexBox)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(241, 240, 240, 0.7);
  z-index: 999;
  box-sizing: border-box;

  @media screen and (max-width: 1024px) {
    padding: 0 20px;
  }
`;

export const Form = styled.form`
  width: 100%;
`;

export const Container = styled(FlexBox)`
  position: relative;
  width: 652px;
  max-height: 90vh;
  overflow: auto;
  padding: 42px 41px 35px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 1024px) {
    width: 100%;
    max-height: 82vh;
    padding: 32px 20px 25px;
  }
`;

export const FormInputsWrapper = styled(FlexBox)`
  width: 100%;
  gap: 20px;
`;

export const ToggleWrapper = styled(FlexBox)`
  margin: 34px 0 35px;
  gap: 13px;
`;

export const InputLabel = styled(Text)<{ noMargin?: boolean }>`
  font-weight: 600;
`;

export const ErrorWrapper = styled.div`
  width: 100%;
  margin-top: 67px;

  @media screen and (max-width: 1024px) {
    margin-top: 30px;
  }
`;

export const RequiredWrapper = styled(FlexBox)`
  width: 100%;
  margin-top: 16px;
`;

export const RequiredText = styled(Text)`
  span {
    font-weight: 600;
    font-size: 14px;
    color: #d47e5b;
  }
`;

export const ButtonsContainer = styled(FlexBox)<{ error: boolean }>`
  width: 100%;
  margin-top: ${({ error }) => (error ? 31 : 0)}px;

  @media screen and (max-width: 1024px) {
    margin-top: ${({ error }) => (error ? 16 : 0)}px;
  }
`;

export const CloseContainer = styled.div`
  position: absolute;
  top: 41px;
  right: 36px;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    top: 32px;
    right: 16px;
  }
`;

export const CloseIcon = styled(FontAwesomeIcon)`
  font-size: 22px;
  color: #4f4f4f;
`;

export const TitleWrapper = styled(FlexBox)`
  margin-bottom: 34px;
  gap: 15px;
`;

export const TitleIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: #4f4f4f;
`;

export const ModalTitle = styled(Text)`
  font-weight: 500;
`;

export const SubmissionContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
`;
