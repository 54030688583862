import React, { useEffect, useState } from 'react';

import { Button } from '@app/components/atoms';

import * as Styled from './styles';
import SlideToggle from '@app/components/atoms/SlideToggle';
import ErrorMessage from '@app/components/atoms/ErrorMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Controller, useForm } from 'react-hook-form';
import Input from '@app/components/atoms/FormInput';
import { useQuery } from '@app/hooks';
import { USER_INTAKE } from '@app/queries/userIntake';
import { CREATE_WAITLIST } from '@app/queries/createWaitlist';
import SubmissionMessage from '../SubmissionMessage';
import * as Sentry from '@sentry/nextjs';

/**
 * Waitlist Form Modal
 */
export interface WaitlistFormModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const WaitlistFormModal = ({ isVisible, onClose }: WaitlistFormModalProps) => {
  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    shouldFocusError: false,
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      isAgent: true,
      agentLicense: '',
      agentMarkets: '',
    },
  });
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isVisible]);

  useEffect(() => {
    const el = document.getElementById('waitlist-container');

    if (el && formSubmitted) {
      el.scrollTo({ top: 0 });
      el.style.overflow = 'hidden';
    } else if (el && !formSubmitted) {
      el.style.overflow = 'auto';
    }
  }, [formSubmitted]);

  const handleClose = () => {
    reset();
    onClose();
  };

  const userIntakeMutation = useQuery({
    query: USER_INTAKE,
    onResult: () => setFormSubmitted(true),
    onFail: (err) => console.log(err),
    useIsLoading: true,
    authMode: 'API_KEY',
  });

  const createWaitlistMutation = useQuery({
    query: CREATE_WAITLIST,
    onResult: () => {
      const slackBody = {
        event: 'Request invitation',
        firstName: watch('firstName'),
        lastName: watch('lastName'),
        email: watch('email'),
        phone: watch('phone'),
        isAgent: watch('isAgent'),
        ...(watch('isAgent') && { agentLicense: watch('agentLicense') }),
        ...(watch('isAgent') && { agentMarkets: watch('agentMarkets') }),
      };

      userIntakeMutation.execute({
        channel: `#${process.env.NEXT_PUBLIC_WAITLIST_SLACK_CHANNEL}`,
        message: JSON.stringify(slackBody),
      });
    },
    onFail: (err) => Sentry.captureException(err),
    useIsLoading: true,
    authMode: 'API_KEY',
  });

  const onSubmit = (data: any) => {
    const body = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email.toLowerCase(),
      phone: data.phone,
      isAgent: data.isAgent,
      ...(watch('isAgent') && { agentLicense: data.agentLicense }),
      ...(watch('isAgent') && { agentMarkets: data.agentMarkets }),
    };
    createWaitlistMutation.execute(body);
  };

  const error = !watch('isAgent')
    ? errors?.['firstName']?.message ||
      errors?.['lastName']?.message ||
      errors?.['email']?.message
    : errors?.['firstName']?.message ||
      errors?.['lastName']?.message ||
      errors?.['email']?.message ||
      errors?.['agentLicense']?.message ||
      errors?.['agentMarkets']?.message;

  return isVisible ? (
    <Styled.Overlay itemsCenter justifyCenter onClick={handleClose}>
      <Styled.Container
        id="waitlist-container"
        onClick={(e) => e.stopPropagation()}
      >
        <Styled.Form onSubmit={handleSubmit(onSubmit)}>
          <Styled.CloseContainer onClick={handleClose}>
            <Styled.CloseIcon icon={['fas', 'times']} />
          </Styled.CloseContainer>

          <Styled.TitleWrapper row itemsCenter>
            <Styled.TitleIcon icon={['fas', 'sign']} />
            <Styled.ModalTitle variant="xl" color="#4F4F4F">
              Join waitlist
            </Styled.ModalTitle>
          </Styled.TitleWrapper>

          <Styled.FormInputsWrapper>
            {/* FIRST NAME */}
            <Input
              placeholder="First name"
              staticTitle
              required
              {...register('firstName', { required: 'First name is required' })}
            />

            {/* LAST NAME */}
            <Input
              placeholder="Last name"
              staticTitle
              required
              {...register('lastName', { required: 'Last name is required' })}
            />

            {/* EMAIL */}
            <Input
              placeholder="Email"
              staticTitle
              required
              {...register('email', {
                required: 'Email is required',
                minLength: {
                  value: 4,
                  message: 'Minimum length should be 4',
                },
                pattern: {
                  value:
                    /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/g,
                  message: 'Invalid email',
                },
              })}
            />

            {/* PHONE */}
            <Input
              placeholder="Phone"
              staticTitle
              mask="999-999-9999"
              {...register('phone')}
            />
          </Styled.FormInputsWrapper>

          <Styled.ToggleWrapper row itemsCenter>
            <Controller
              control={control}
              name="isAgent"
              render={({ field: { onChange, value } }) => (
                <SlideToggle
                  checked={value}
                  handleToggle={() => onChange(!value)}
                />
              )}
            />
            <Styled.InputLabel color="#4F4F4F">
              Are you an agent?
            </Styled.InputLabel>
          </Styled.ToggleWrapper>

          <Styled.FormInputsWrapper>
            {/* LICENSE NUMBER */}
            <Input
              placeholder="License number"
              staticTitle
              required
              readOnly={!watch('isAgent')}
              {...register('agentLicense', {
                required: watch('isAgent')
                  ? 'License number is required'
                  : false,
              })}
            />

            {/* MARKETS SERVED */}
            <Input
              placeholder="Markets served"
              staticTitle
              required
              readOnly={!watch('isAgent')}
              {...register('agentMarkets', {
                required: watch('isAgent')
                  ? 'Markets served is required'
                  : false,
              })}
            />
          </Styled.FormInputsWrapper>

          <Styled.ErrorWrapper>
            <ErrorMessage error={Boolean(error)} errorMsg={error} />
          </Styled.ErrorWrapper>

          <Styled.ButtonsContainer
            row
            itemsCenter
            justifyBetween
            error={Boolean(error)}
          >
            <Button
              variant="tertiary"
              onClick={handleClose}
              disabled={
                createWaitlistMutation.loading || userIntakeMutation.loading
              }
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={
                createWaitlistMutation.loading || userIntakeMutation.loading
              }
              loading={
                createWaitlistMutation.loading || userIntakeMutation.loading
              }
            >
              Submit
            </Button>
          </Styled.ButtonsContainer>

          <Styled.RequiredWrapper itemsEnd>
            <Styled.RequiredText variant="sm" bold color="#4f4f4f">
              <span>
                <FontAwesomeIcon icon={['far', 'asterisk']} size="sm" />
              </span>{' '}
              Required
            </Styled.RequiredText>
          </Styled.RequiredWrapper>
        </Styled.Form>

        {formSubmitted ? (
          <Styled.SubmissionContainer>
            <SubmissionMessage
              title="You have been added to the waitlist!"
              subtitle="We'll contact you when additional openings become available in the Trusty private network. If you're aware of another agent in the network, request a referral to advance your position on the list."
              bgColor="rgb(254, 254, 242)"
              clickClose={() => {
                handleClose();
                setFormSubmitted(false);
              }}
              titleMargin={22}
            />
          </Styled.SubmissionContainer>
        ) : null}
      </Styled.Container>
    </Styled.Overlay>
  ) : null;
};

export default WaitlistFormModal;
