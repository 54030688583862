import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';

export type ToggleVariant = 'primary' | 'secondary';

export const Container = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
`;

export type VariantModifiersProps = {
  checked?: boolean;
  disabled?: boolean;
};

const variantModifiers = ({ checked, disabled }: VariantModifiersProps) => ({
  primary:
    checked &&
    css`
      background: ${disabled ? 'rgba(212, 126, 91, 0.5)' : '#D47E5B'};
      border: 1px solid ${disabled ? 'rgba(200, 105, 66, 0.5)' : '#C86942'};
    `,
  secondary:
    checked &&
    css`
      background: ${disabled ? 'rgba(79, 202, 174, 0.5)' : '#4FCAAE'};
      border: 1px solid ${disabled ? 'rgba(47, 175, 146, 0.5)' : '#2FAF92'};
    `,
});

export const SwitchLabel = styled.label<{
  checked: boolean;
  disabled?: boolean;
  variant?: ToggleVariant;
  yellow?: boolean;
  uncheckedBorderColor?: string;
  uncheckedBackgroundColor?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  width: 46px;
  height: 24px;
  border-radius: 100px;
  position: relative;
  transition: background-color border 0.2s;
  padding: 0 8px;
  box-sizing: border-box;

  background: ${({ disabled, yellow, uncheckedBackgroundColor }) =>
    disabled
      ? 'rgba(217, 217, 217, 0.5)'
      : yellow
      ? '#FFCC4D'
      : uncheckedBackgroundColor || '#D9D9D9'};
  border: 1px solid
    ${({ disabled, yellow, uncheckedBorderColor }) =>
      disabled
        ? 'rgba(198, 198, 198, 0.5)'
        : yellow
        ? '#D9AE42'
        : uncheckedBorderColor || '#C6C6C6'};

  ${({ variant = 'primary', checked, disabled }) =>
    variantModifiers({ checked, disabled })[variant]};
`;

export const SwitchButton = styled.span<{
  checked: boolean;
  disabled?: boolean;
}>`
  content: '';
  position: absolute;
  top: 1.5px;
  left: ${({ checked }) => (checked ? 'calc(100% - 20.5px)' : '1.5px')};
  width: 19px;
  height: 19px;
  border-radius: 20px;
  transition: 0.2s;
  background: ${({ disabled }) =>
    disabled ? 'rgba(255, 255, 255, 0.75)' : '#ffffff'};
  z-index: 3;
`;

const iconVariantModifiers = ({ checked }: VariantModifiersProps) => ({
  primary: css`
    color: ${checked ? '#F6F6F6' : '#F5D4C6'};
  `,
  secondary: css`
    color: ${checked ? '#F6F6F6' : '#F9F9F9'};
  `,
});

export const Icon = styled(FontAwesomeIcon)<{
  variant?: ToggleVariant;
  checked: boolean;
}>`
  font-size: 13px;
  ${({ variant = 'primary', checked }) =>
    iconVariantModifiers({ checked })[variant]};
  opacity: ${({ checked }) => (checked ? 1 : 0)};
  transition-delay: 0.2;
  transition: opacity 1s;
`;
